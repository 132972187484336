<template>
  <div>
    <template v-if="score === 'Above Average'">
      <h3>
        <span
          ><svg
            :class="{
              svgPdf: $route.path === '/pdf',
              svgWeb: $route.path === '/',
            }"
            xmlns="http://www.w3.org/2000/svg"
            width="104.451"
            height="55.035"
            viewBox="0 0 104.451 55.035"
          >
            <g
              id="Blancco_Sustainable_Dial-High"
              data-name="Blancco Sustainable Dial-High"
              transform="translate(-5.218 -4.82)"
            >
              <path
                id="Path_9499"
                data-name="Path 9499"
                d="M30.85,11.32A52.162,52.162,0,0,0,5.32,55.968H23.506A33.89,33.89,0,0,1,39.943,27.058"
                transform="translate(-0.102 0.96)"
                fill="#cf1f2f"
              />
              <path
                id="Path_9500"
                data-name="Path 9500"
                d="M53.733,4.92A51.91,51.91,0,0,0,28.32,11.565L37.413,27.3a33.476,33.476,0,0,1,32.874,0L79.38,11.565A53.489,53.489,0,0,0,53.733,4.92Z"
                transform="translate(3.711 -0.1)"
                fill="#f08920"
              />
              <path
                id="Path_9501"
                data-name="Path 9501"
                d="M74.313,11.32,65.22,27.058A33.89,33.89,0,0,1,81.657,55.968H99.843A52.162,52.162,0,0,0,74.313,11.32Z"
                transform="translate(9.827 0.96)"
                fill="#2eb77f"
              />
              <circle
                id="Ellipse_20"
                data-name="Ellipse 20"
                cx="3.264"
                cy="3.264"
                r="3.264"
                transform="translate(54.18 53.326)"
                :class="{
                  st3: $route.path === '/pdf',
                  st4: $route.path === '/',
                }"
              />
              <path
                id="Path_9502"
                data-name="Path 9502"
                d="M49.73,49.934,76.694,35.141c.5-.268.816.256.338.571L51.63,52.86"
                transform="translate(7.259 4.896)"
                :class="{
                  st3: $route.path === '/pdf',
                  st4: $route.path === '/',
                }"
              />
            </g></svg
        ></span>

        <span
          class="inL"
          :class="{
            rank: $route.path === '/pdf',
            rankSmall: $route.path === '/',
          }"
        >
          Above Average<br /><span class="small" v-if="$route.path === '/'"
            >compared to other public sector organizations.</span
          >
        </span>
      </h3>
    </template>
    <template v-if="score === 'Below Average'">
      <h3>
        <span>
          <svg
            :class="{
              svgPdf: $route.path === '/pdf',
              svgWeb: $route.path === '/',
            }"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 89.6 47.2"
            style="enable-background:new 0 0 89.6 47.2;"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  class="st0"
                  d="M21.9,6.4C8.8,14.2,0.1,28.4,0,44.7h15.6c0-10.5,5.7-19.7,14.1-24.8"
                />
                <path
                  class="st1"
                  d="M44.8,0C36.9,0,29.4,2.1,23,5.7l7.8,13.5c4.2-2.3,8.9-3.7,14.1-3.7s9.9,1.4,14.1,3.7l7.8-13.5
			C60.2,2.1,52.7,0,44.8,0z"
                />
              </g>
              <path
                class="st2"
                d="M67.7,6.4l-7.8,13.5C68.3,25,74,34.2,74,44.7h15.6C89.5,28.4,80.8,14.2,67.7,6.4z"
              />
            </g>
            <g>
              <circle
                :class="{
                  st3: $route.path === '/pdf',
                  st4: $route.path === '/',
                }"
                cx="44.8"
                cy="44.4"
                r="2.8"
              />
              <path
                :class="{
                  st3: $route.path === '/pdf',
                  st4: $route.path === '/',
                }"
                d="M43.5,45.4L21.8,30.7c-0.4-0.3-0.1-0.7,0.3-0.5l23.1,12.7"
              />
            </g></svg
        ></span>
        <span
          class="inL"
          :class="{
            rank: $route.path === '/pdf',
            rankSmall: $route.path === '/',
          }"
          >Below Average<br /><span class="small" v-if="$route.path === '/'"
            >compared to other public sector organizations.</span
          ></span
        >
      </h3>
    </template>
    <template v-if="score === 'In Line'">
      <h3>
        <span
          ><svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 89.6 47.2"
            style="enable-background:new 0 0 89.6 47.2;"
            xml:space="preserve"
            :class="{
              svgPdf: $route.path === '/pdf',
              svgWeb: $route.path === '/',
            }"
          >
            <g>
              <g>
                <path
                  class="st0"
                  d="M21.9,6.4C8.8,14.2,0.1,28.4,0,44.7h15.6c0-10.5,5.7-19.7,14.1-24.8"
                />
                <path
                  class="st1"
                  d="M44.8,0C36.9,0,29.4,2.1,23,5.7l7.8,13.5c4.2-2.3,8.9-3.7,14.1-3.7s9.9,1.4,14.1,3.7l7.8-13.5
			C60.2,2.1,52.7,0,44.8,0z"
                />
              </g>
              <path
                class="st2"
                d="M67.7,6.4l-7.8,13.5C68.3,25,74,34.2,74,44.7h15.6C89.5,28.4,80.8,14.2,67.7,6.4z"
              />
            </g>
            <g>
              <circle
                :class="{
                  st3: $route.path === '/pdf',
                  st4: $route.path === '/',
                }"
                cx="44.8"
                cy="44.4"
                r="2.8"
              />
              <path
                :class="{
                  st3: $route.path === '/pdf',
                  st4: $route.path === '/',
                }"
                d="M43.3,43.9l1.3-26.3c0-0.5,0.5-0.5,0.6,0L46.3,44"
              />
            </g></svg></span
        ><span
          class="inL"
          :class="{
            rank: $route.path === '/pdf',
            rankSmall: $route.path === '/',
          }"
          >In Line with the Industry Average<br /><span
            class="small"
            v-if="$route.path === '/'"
            >compared to other public sector organizations.</span
          ></span
        >
      </h3>
    </template>
  </div>
</template>

<script>
export default {
  name: "dials",
  props: ["score"],
};
</script>

<style scoped>
.svgPdf {
  vertical-align: baseline;
  width: 104px;
  height: 55px;
}

.svgWeb {
  width: 49px;
  height: 26px;
}
.rank {
  text-align: left;
  font: normal normal 900 72px/76px "aktiv-grotesk";
  letter-spacing: 0px;
  color: #3b5eab;
  text-transform: uppercase;
  width: 956px;
  left: 266px;
  top: 244px;
  display: block;
}
.rankSmall {
  text-align: left;
  font: normal normal bold 20px/32px "aktiv-grotesk";
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  margin-left: 10px;
  display: inline;
}
.st0 {
  fill: #cf1f2f;
}
.st1 {
  fill: #f08920;
}
.st2 {
  fill: #2eb77f;
}
.st3 {
  fill: #253a6b;
}
.st4 {
  fill: #fff;
}
.small {
  font: normal normal normal 18px/32px "aktiv-grotesk";
}
@media only screen and (max-width: 500px) {
  .rankSmall {
    display: inline-flex;
  }
  .inL {
    margin-left: 0;
  }
  .small {
    display: contents;
  }
}
</style>
