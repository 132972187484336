<template>
  <div>
    <template v-if="question === 1">
      <p class="answer Aone">
        <template v-if="value === '3'"
          ><p>
            You know data protection regulations, directives, and guidelines
            very well—and you’re in good company. Our research showed that
            respondents were familiar with the vast majority of data protection
            laws and sanitization guidelines, particularly those that are
            internationally referenced and viewed as most robust. These include
            the UK Data Protection Act (DPA) 2018 and EU General Data Protection
            Regulation (GDPR), the ISO 27000 standard, and NIST Media
            Sanitization Guidelines (NIST SP 800-88r1).
          </p>
          <p>
            In fact, a whopping 100 percent of our respondents had heard of the
            EU GDPR, the DPA, and ISO 27000.
          </p>
        </template>
        <template v-if="value === '2'"
          ><p>
            Knowing data protection regulations, directives, and guidelines
            well—but not in detail—puts your organization at a greater risk of a
            data breach. It can also mean your protocols are more extreme—and
            likely more costly—than is necessary.
          </p>
          <p>
            In our research, we found that the US National Security
            Agency/Central Security Service (NSA/CSS) had the greatest
            percentage (88 percent) of respondents who are “aware of and know
            some detail” or are “aware of and know in detail.” NSA/CSS Policy
            Manual 9-12 advocates a non-reuse approach for assets containing
            classified information. As the most well-known US IT policy for
            dealing with critical information, we believe it may also influence
            what other IT leaders consider standard device sanitization and
            disposal, regardless of classification.
          </p>
        </template>
        <template v-if="value === '1'"
          ><p>
            So, you’ve indicated you’re not very familiar with data regulations,
            directives, and guidelines. This is a concern as it puts your
            organization at a significant risk of a data breach. It also
            suggests your policies and processes don’t align with best
            practices—meaning you could be overspending and impacting the
            environment more than is necessary.
          </p>
          <p>
            Sixty-nine percent of countries globally have data protection and
            privacy legislation in place. It’s essential to familiarize yourself
            with the latest laws and guidelines—from the UK Data Protection Act
            (DPA) and the EU General Data Protection Regulation (GDPR) to
            Japan’s Amended Act on the Protection of Personal Information
            (Amended APPI). The ramifications of poor data handling are costly
            and can lead to severe reputational damage.
          </p>
        </template>
      </p>
    </template>
    <template v-if="question === 2">
      <p class="answer Atwo">
        <template v-if="value === '3'"
          ><p>
            You go the extra mile when it comes to SSD sanitization.
            Congratulations, you’re blazing a trail with your processes and
            setting an example for others to follow. Our research revealed that
            35 percent of respondents believed there is no certified or approved
            vendor or solution that provides a sustainable option for secure
            data destruction. You’ve clearly done your homework to see what’s
            out there, and by adopting more eco-friendly solutions such as
            software-based erasure, you’re not just hitting your sustainability
            goals, you’re also putting taxpayers’ money to better use.
          </p>
        </template>
        <template v-if="value === '2'"
          ><p>
            You selected “Cryptographic erasure / encryption,” “Reformatting,”
            or “Software-based erasure and physical destruction.” While your
            organization, and the public sector average, are aware of the need
            to protect data on end-of-life assets, these options leave room for
            improvement.
          </p>
          <p>
            For instance, cryptographic erasure is vulnerable to poor
            implementation that can leave data accessible or elevate risk of key
            breakage. By nature, reformatting often leaves data behind without
            any visibility into what data remains. Even when combined with
            physical destruction, these methods can result in SSDs retaining
            sensitive data before destruction can occur.
          </p>
          <p>
            On the other hand, combining data erasure with destruction is
            secure, but often includes prematurely ending the life of usable,
            valuable devices, short-circuiting the chance to extract greater
            value through device reuse. Physically destroying devices after
            erasure also produces unnecessary e-waste, contributing to
            environmental harm.
          </p>
          <p>
            The good news is that Blancco can permanently and securely erase all
            your data immediately, rendering physical destruction unnecessary.
            Our proven software-based solutions can be deployed as part of the
            decommissioning process, preventing unauthorized access from the
            soonest possible moment. Our software will also verify complete
            erasure and produce an audit-ready report—certifying the erasure and
            documenting compliance with industry and regulatory requirements.
          </p>
          <p>
            <a :href="link" target="_blank">Get your free trial today</a>
          </p>
        </template>
        <template v-if="value === '1'"
          ><p>
            You physically destroy your SSDs. While a traditional practice, this
            process incurs substantial costs for both destruction and
            replacement, contributes to growing e-waste, and can involve many
            points of data breach vulnerability. As a result, relying on the
            physical destruction of usable drives and devices unnecessarily
            harms the environment and can lead to breached data and compliance
            penalties. We strongly recommend you revisit your processes and
            revise your policies to allow for more secure and sustainable
            solutions.
          </p>
          <p>
            <a :href="link" target="_blank">Get your free trial today</a>
          </p>
        </template>
      </p>
    </template>
    <template v-if="question === 3">
      <p class="answer Athree">
        <template v-if="value === '3'"
          ><p>
            Destroying fewer than 1,000 SSDs a year makes your organization more
            environmentally friendly than the average public sector body with
            more than 1,000 employees—well done.
          </p>
          <p>
            Of those surveyed for our research, 55 percent worked for
            organizations with more than 1,000 and less than 5,000 employees,
            and 45 percent for organizations having 5,000 employees or more. In
            both categories, organizations annually destroyed roughly one SSD
            for every three employees.
          </p>
          <p>
            Unnecessary SSD destruction increases IT operations and materials
            costs for fiscally constrained public sector organizations. It also
            fosters increased electronic waste (e-waste) creation during a
            global call for more prudent environmental stewardship. As a public
            body, you limit your SSD destruction and set a good example for
            other organizations around you.
          </p>
        </template>
        <template v-if="value === '2'">
          <p>
            Destroying between 1,001 and 2,000 SSDs every year puts you in line
            with other public sector bodies with more than 1,000 employees. But
            you could lower these numbers by introducing a data erasure software
            solution—like Blancco’s. In turn, you would save more money and
            reduce the risk of data breaches.
          </p>
          <p>
            Of those surveyed, 55 percent worked for organizations with 1,000 to
            5,000 employees, while 45 percent worked for organizations with
            5,000 employees or more. In both categories, organizations annually
            destroyed roughly one SSD for every three employees.
          </p>
          <p>
            Today’s technology allows you to limit the impact on your budget and
            our planet. Unnecessary SSD destruction increases IT operations and
            material costs for fiscally constrained public sector organizations.
            It also fosters increased electronic waste (e-waste) creation during
            a global call for more prudent environmental stewardship. Pursuing a
            software-based solution that allows secure, confident device reuse
            allows you to soundly protect your data, comply with regulations,
            and support environmental sustainability goals.
          </p>
          <p>
            <a :href="link" target="_blank">Get your free trial today.</a>
          </p></template
        >
        <template v-if="value === '1'">
          <p>
            Destroying more than 2,000 SSDs a year means your organization
            eliminates significantly more drives than the average public sector
            body. Excessive drive destruction is sure to have a negative
            impact—not just on the environment—but on your budget, too.
          </p>
          <p>
            Unnecessary SSD destruction increases IT operations and material
            costs for fiscally constrained public sector organizations. It also
            fosters increased electronic waste (e-waste) creation during a
            global call for more prudent environmental stewardship.
          </p>
          <p>
            Of those surveyed, 55 percent worked for organizations with 1,000 to
            5,000 employees, while 45 percent worked for organizations with
            5,000 employees or more. In both categories, organizations annually
            destroyed roughly one SSD for every three employees.
          </p>
          <p>
            Today’s data erasure technology can have a great impact on your
            budget and our planet. Pursuing a software-based solution that
            allows secure, confident device reuse allows you to soundly protect
            your data, comply with regulations, reduce destruction and
            replacement costs, and support environmental sustainability goals.
          </p>
          <p>
            <a :href="link" target="_blank">Get your free trial today</a>
          </p></template
        >
      </p>
    </template>
    <template v-if="question === 4">
      <p class="answer Afour">
        <template v-if="value === '3'"
          ><p>
            By spending less than $50,000 USD to destroy and replace SSDs every
            year, your organization is performing better than the average public
            sector body.
          </p>
          <p>
            Respondents of our survey reported destroying an average of 1,433
            SSDs a year. Between spending an average of $65,235 on new SSDs and
            up to $28,660 on drive destruction, the cash soon stacks up. As does
            the e-waste.
          </p>
          <p>
            It’s great that you’re coming in below this, but it’s still worth
            considering if there’s anything else you can do to bring this cost
            down further.
          </p>
          <p>
            <a :href="link" target="_blank">Get your free trial today</a>
          </p>
        </template>
        <template v-if="value === '2'">
          <p>
            By spending between $51,000 and $100,000 USD to destroy and replace
            SSDs every year, your organization performed in line with the
            average public sector body.
          </p>
          <p>
            On average, our respondents destroyed 1,433 SSDs a year. Between
            spending an average of $65,235 on new SSDs and up to $28,660 on
            drive destruction, the cash soon stacks up. As does the e-waste.
          </p>
          <p>
            It’s important to recognize that even the industry average is
            incredibly high, and you could easily save your organization money
            by updating your sanitization processes and policies to line up with
            the latest technological advancements. What’s more, you’ll gain the
            peace of mind that your methods are 100 percent secure and far more
            sustainable.
          </p>
          <p>
            <a :href="link" target="_blank">Get your free trial today</a>
          </p></template
        >
        <template v-if="value === '1'">
          <p>
            Unfortunately, your organization is performing below the average
            public sector body when it comes to the costs of destroying and
            replacing SSDs. You told us your organization spends more than
            $100,000 USD every year.
          </p>
          <p>
            How you spend the taxpayer’s money will always face intense
            scrutiny. The good news is you can easily save your organization
            money by updating your sanitization processes and policies to line
            up with the latest technological advancements. What’s more, you’ll
            also gain the peace of mind that your methods are 100 percent secure
            and far more sustainable.
          </p>
          <p>
            <a :href="link" target="_blank">Get your free trial today</a>
          </p></template
        >
      </p>
    </template>
    <template v-if="question === 5">
      <p class="answer Afive">
        <template v-if="value === '3'">
          <p>
            Thankfully, your policies don’t dictate that your end-of-life drives
            need to be physically destroyed. Great work—you’ve clearly kept an
            eye on the market and updated your organization’s policies and
            sanitization methods accordingly. This puts you ahead of many other
            organizations.
          </p>
          <p>
            However, that’s not the case for everyone. Our research revealed
            that because it’s mandated by law to physically destroy SSDs that
            contain classified data, forty-one percent of respondents choose to
            destroy all SSDs “just in case.” This means many SSDs are being
            destroyed instead of reused, simply due to perceived ease or an
            abundance of caution. We believe legacy policies are a significant
            roadblock, stopping organizations from updating their methods to
            those that extend the life of valuable technology—and demonstrate
            fiscal and environmental responsibility.
          </p></template
        >
        <template v-if="value === '1'">
          <p>
            According to your organization’s policies, your end-of-life drives
            must be physically destroyed. Concerningly, our research revealed
            that, because it’s mandated by law to physically destroy SSDs that
            contain classified data, 41 percent of respondents choose to destroy
            all SSDs “just in case.” This means many SSDs are being destroyed
            instead of reused, simply due to perceived ease or an abundance of
            caution.
          </p>
          <p>
            What’s more, as non-device-destructive data sanitization methods
            become more widely used as a secure option, legacy policies could be
            the roadblock stopping your organization from upgrading your
            process, supporting national and international environmental
            mandates, and reducing costs. As such, we encourage you to review
            your policies to ensure they offer accurate guidance based on a
            secure, sustainable solution that extends the life of valuable
            technology—and demonstrates fiscal and environmental responsibility.
          </p>
          <p>
            <a :href="link" target="_blank">Get your free trial today</a>
          </p></template
        >
      </p>
    </template>
    <template v-if="question === 6">
      <p class="answer Asix">
        <template v-if="value === '3'">
          <p>
            You’ve got top marks on this one. It sounds like you’re staying on
            top of regulations and updating your policies in a timely manner.
            With an increased focus on environmental sustainability, and the
            rapid acceleration of data transformation, there is a need to
            consider all options allowed under governing regulations. Many
            policies such as NIST SP 800-88 r1 support non-device-destructive
            options for secure data sanitization, particularly for
            non-classified data. Policies need to fit today’s evolving
            government technology landscape, so it’s great that you’re keeping
            this front of mind for your organization.
          </p></template
        >
        <template v-if="value === '2'"
          ><p>
            It sounds like you’ve got a policy review process in place, and it's
            becoming increasingly urgent to see how your organization approaches
            data sanitization for decommissioned drives and devices. With an
            increased focus on environmental sustainability, and the rapid
            acceleration of data transformation, a lot can change in a short
            amount of time—and it has.
          </p>
          <p>
            Many policies such as NIST SP 800-88 r1 provide for a
            non-device-destructive approach for secure data sanitization,
            particularly for non-classified data. Even for advanced data storage
            devices, secure, software-based data erasure can ensure purge-level
            destruction so that valuable IT assets can be safely reused.
            Policies need to fit today’s evolving government technology
            landscape, so we strongly recommend you prioritize reviewing and
            updating yours to align with the latest guidance.
          </p>
        </template>
        <template v-if="value === '1'">
          <p>
            It’s either been over a year or longer since you last reviewed your
            policy. Needless to say, a lot can change in that amount of time—and
            it has. With an increased focus on environmental sustainability, and
            the rapid acceleration of data transformation, there is a need to
            consider all options allowed under governing regulations. Many
            policies such as NIST SP 800-88 r1 provide for a
            non-device-destructive approach for secure data sanitization,
            particularly for non-classified data.
          </p>
          <p>
            Even for advanced data storage devices, secure, software-based data
            erasure can ensure purge-level destruction so that valuable drives
            and devices can be safely reused. Policies need to fit today’s
            evolving government technology landscape, so we strongly recommend
            you prioritize reviewing and updating your policies in line with the
            latest guidance.
          </p></template
        >
      </p>
    </template>
    <template v-if="question === 7">
      <p class="answer Aseven">
        <template v-if="value === '3'"
          ><p>
            Your data sanitization plans take into account sustainability best
            practices, and they have been implemented. This is great news
            and—according to our data—puts you ahead of most organizations. In
            our research, we discovered that although 93 percent of respondents
            have defined plans to reduce the environmental impact caused by
            destroying IT equipment, less than a quarter (21 percent) are
            actively implementing them.
          </p>
        </template>
        <template v-if="value === '2'">
          <p>
            You already have a data sanitization plan that takes into account
            sustainability best practices—but you’ve yet to implement it.
            According to our data, this puts you on par with most public sector
            organizations. Although 93 percent of respondents have defined plans
            to reduce the environmental impact caused by destroying IT
            equipment, less than a quarter (21 percent) are actively
            implementing them. Of course, having the plan is a significant step
            forward, but now it’s time to put it into action.
          </p>
          <p>
            <a :href="link" target="_blank">Get your free trial today</a>
          </p></template
        >
        <template v-if="value === '1'">
          <p>
            Off the back of COP26, organizations—both big and small—are expected
            to do more to help tackle the climate crisis. According to your
            response, you’ve yet to make a plan to offset the environmental
            impact of your current data sanitization methods. This puts you
            behind the average public sector body. The good news is, you can
            make a significant difference by upgrading your processes. With
            Blancco, you can erase end-of-life data and make it 100 percent
            irretrievable. So, you can reuse or resell your drives with complete
            peace of mind.
          </p>          <p>
            <a :href="link" target="_blank">Get your free trial today</a>
          </p></template
        >
      </p>
    </template>
    <template v-if="question === 8">
      <p class="answer Aeight">
        <template v-if="value === '3'"
          ><p>
            Without wishing to tempt fate, you told us you’ve never had an
            end-of-life data breach. Naturally, you want to keep it this way.
            According to IBM’s “2022 Cost of a Data Breach Report,” within the
            public sector, the average cost of a breach surged by 78.7 percent
            globally between 2020 and 2021, from $1.08 million to $1.93 million.
            So, how do you continue to minimize the risk? By adopting more
            innovative sanitization solutions, such as Blancco data erasure, and
            reviewing your policies, you’ll be able to ensure even highly
            classified data is permanently irretrievable throughout your IT
            assets’ extended lifecycle.
          </p>
          <p>
            <a :href="link" target="_blank">Get your free trial today.</a>
          </p>
        </template>
        <template v-if="value === '1'"
          ><p>
            Since you’ve previously had an end-of-life data breach, you’ll know
            first-hand how damaging it can be to your organization—both in terms
            of your reputation and the financial penalties incurred. According
            to IBM’s “2022 Cost of a Data Breach Report,” within the public
            sector, the average cost of a breach of any kind surged by a
            whopping 78.7 percent globally between 2020 and 2021, from $1.08
            million to $1.93 million. So, how do you reduce the risk of this
            happening again? By adopting more innovative sanitization solutions,
            such as Blancco data erasure, and reviewing your policies, you’ll be
            able to ensure even highly classified data is permanently
            irretrievable throughout your IT assets’ extended lifecycle.
          </p>
          <p>
            <a :href="link" target="_blank">Get your free trial today</a>
          </p>
        </template>
      </p>
    </template>
    <template v-if="question === 9">
      <p class="answer Anine">
        <template v-if="value === '3'"
          ><p>
            Your organization stands with 13 percent of public sector
            organizations around the world who that have full confidence in
            their physical destruction process. However, if your physical
            destruction efforts aren’t combined with software-based data
            erasure, you’re at a higher risk of a data breach—and perhaps this
            confidence is misplaced. Physical destruction alone does not
            guarantee your data is irretrievable, particularly at vulnerable
            points along the chain of custody, including transport, storage, and
            processing.
          </p>
          <p>
            To learn more about best practices and the current public sector
            landscape, download and read our report, <a href="https://www.blancco.com/ps-the-price-of-destruction/?utm_source=pdf&utm_medium=benchmarking-report&utm_content=benchmarking-tool-link&utm_campaign=publicsector" target="_blank">The Price of Destruction,
            here.</a>
          </p>
        </template>
        <template v-if="value === '1'"
          ><p>
            We’re sorry to hear you don’t have complete confidence in your
            organization’s physical data destruction processes. Rest assured,
            you are not alone. In our research, we found that only 13 percent
            strongly agree that they have full confidence in their
            organization’s physical destruction process. The good news is, we
            can help. Blancco’s secure data erasure can guarantee 100 percent
            erasure of your drives for 100 percent peace of mind.
          </p>
          <p>
            <a :href="link" target="_blank">Get your free trial today</a>
          </p>
        </template>
      </p>
    </template>
  </div>
</template>

<script>
export default {
  name: "answers",
  props: ["question", "value"],
  data(){
    return{
      link:"https://www.blancco.com/demo/free-trial-request-enterprise/?utm_source=benchmarking-tool-report&utm_medium=pdf&utm_content=enterprise-free-trial-cta&utm_campaign=publicsector"
    }
  }
};
</script>

<style scoped>
.answer {
  text-align: left;
  font: normal normal normal 20px/32px "aktiv-grotesk";
  letter-spacing: 0px;
  color: #5d6770;
}
a{
  color: #5d6770!important;
  text-decoration: underline;
}
</style>
