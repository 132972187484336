<template>
  <div class="home">
    <div class="grad">
      <section
        class="page lander"
        :style="
          'background-image:url(' +
            require('@/assets/img/Tiny_squares.png') +
            ');'
        "
      >
        <template v-for="(n, index) in bubLoop">
          <Bubbles :key="index" :id="index" slide="pdf" />
        </template>
        <div>
          <div class="line"></div>
          <h2>
            {{ $route.query.b }}
          </h2>
          <h1>DATA SANITIZATION REPORT</h1>
          <p class="date">{{ date }}</p>
          <b-img
            class="laptop"
            :src="require('@/assets/img/Mac.png')"
            alt="Laptop being shredded"
          />
        </div>
      </section>
    </div>
    <div class="page">
      <section class="report">
        <div>
          <template v-if="score === 'Above Average'">
            <div>
              <h4 class="engage">
                Great work.
              </h4>
              <p class="engageText">
                Your organization’s data sanitization policies and processes
                are:
              </p>
              <Dial :score="score" />
            </div>
          </template>
          <template v-if="score === 'In Line'">
            <div>
              <h4 class="engage">
                You’re doing OK.
              </h4>
              <p class="engageText">
                Your organization’s data sanitization policies and processes
                are:
              </p>
              <Dial :score="score" />
            </div>
          </template>
          <template v-if="score === 'Below Average'">
            <div>
              <h4 class="engage">
                There’s work to be done.
              </h4>
              <p class="enageText">
                Your organization’s data sanitization policies and processes
                are:
              </p>
              <Dial :score="score" />
            </div>
          </template>
          <p class="breakdown">
            Here’s a breakdown of where you stand, question by question.
          </p>
          <template v-for="(sheet, index) in this.$route.query.a">
            <div :key="index">
              <div class="qa" :class="{ q: index === 0 }">
                <Question :question="index + 1" />
              </div>
              <div class="qa">
                <Answers :question="index + 1" :value="sheet" />
              </div>
              <div class="d">
                <DidYou :question="index + 1" />
              </div>
            </div>
          </template>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Bubbles from "@/components/bubbles.vue";
import Answers from "@/components/answers.vue";
import Question from "@/components/questions.vue";
import DidYou from "@/components/didYou.vue";
import Dial from "@/components/dials.vue";
export default {
  components: {
    Bubbles,
    Answers,
    Question,
    DidYou,
    Dial,
  },
  data() {
    return {
      bubLoop: 4,
    };
  },
  computed: {
    date() {
      const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const d = new Date();
      let name = month[d.getMonth()];
      let year = d.getFullYear();
      return name + " " + year;
    },
    score(pro) {
      var int = [
        parseInt(this.$route.query.a[0]),
        parseInt(this.$route.query.a[1]),
        parseInt(this.$route.query.a[2]),
        parseInt(this.$route.query.a[3]),
        parseInt(this.$route.query.a[4]),
        parseInt(this.$route.query.a[5]),
        parseInt(this.$route.query.a[6]),
        parseInt(this.$route.query.a[7]),
        parseInt(this.$route.query.a[8]),
      ];
      var sum = int.reduce((acc, item) => acc + item, 0);
      if (sum >= 8 && sum <= 14) {
        pro = "Below Average";
      } else if (sum >= 15 && sum <= 20) {
        pro = "In Line";
      } else if (sum >= 21 && sum <= 27) {
        pro = "Above Average";
      }
      return pro;
    },
  },
};
</script>

<style scoped>
.grad {
  background: transparent linear-gradient(147deg, #253a6b 0%, #2eb77f 100%) 0%
    0% no-repeat padding-box;
  height: 1682px;
}
.home {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 1240px;
}
.lander {
  background-repeat: no-repeat;
  background-size: cauto;
  height: 1682px;
  position: relative;
  width: 1240px;
}
.line {
  top: 552px;
  left: 142px;
  width: 956px;
  height: 12px;
  background-color: #fff;
  position: absolute;
}
h2 {
  top: 580px;
  left: 142px;
  width: 956px;
  height: 62px;
  text-align: left;
  font: normal normal 900 48px/76px "aktiv-grotesk";
  letter-spacing: -0.49px;
  color: #ffffff;
  text-transform: uppercase;
  position: absolute;
}
h1 {
  position: absolute;
  top: 642px;
  left: 142px;
  width: 956px;
  height: 168px;
  text-align: left;
  font: normal normal 900 72px/76px "aktiv-grotesk";
  letter-spacing: -0.73px;
  color: #ffffff;
  text-transform: uppercase;
}
.date {
  position: absolute;
  top: 834px;
  left: 142px;
  width: 956px;
  height: 26px;
  text-align: left;
  font: normal normal normal 20px/32px "aktiv-grotesk";
  letter-spacing: 0px;
  color: #fff;
}
.laptop {
  position: absolute;
  top: 921px;
  left: 390px;
  width: 850px;
  height: 534px;
}
.report {
  padding: 142px 142px 0 142px;
}

.engage {
  background: transparent linear-gradient(92deg, #253a6b 0%, #3b5eab 100%) 0% 0%
    no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font: normal normal 500 28px/39px "aktiv-grotesk";
}
.engageText {
  width: 956px;
  text-align: left;
  font: normal normal normal 20px/32px "aktiv-grotesk";
  letter-spacing: 0px;
  color: #5d6770;
  padding-bottom: 32px;
}

.breakdown {
  top: 426px;
  left: 142px;
  width: 956px;
  height: 26px;
  text-align: left;
  font: normal normal normal 20px/32px "aktiv-grotesk";
  letter-spacing: 0px;
  color: #5d6770;
}
.q {
  padding: 104px 0 8px !important;
}
.qa {
  padding: 8px 0;
}
.d {
  padding-bottom: 40px;
}
</style>
