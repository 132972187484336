<template>
  <div id="app" :class="{ pdf: $route.name === 'test' }">
    <!-- Nav Menu -->
    <Nav />

    <!-- Key Full Path enables to use the same page template with diffrent data and will refresh -->
    <router-view :key="$route.fullPath" />

    <!-- Footer Menu -->
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";

export default {
  components: {
    Nav,
  },
};
</script>

<style>
#app {
  background: transparent linear-gradient(147deg, #223a72 0%, #2eb77f 100%) 0%
    0% no-repeat padding-box;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
  height: 100vh;

}
.pdf {
  height: auto !important;
  width: 1240px!important;
  background: none !important;
}
</style>
