<template>
  <div class="home">
    <script
      id="Cookiebot"
      src="https://consent.cookiebot.com/uc.js"
      data-cbid="3c58367e-df64-4e9f-a779-6cf5fd2aef49"
      type="application/javascript"
      data-blockingmode="auto"
    ></script>
    <div
      class="bubbles"
      :style="
        'background-image:url(' +
          require('@/assets/img/Tiny_squares.png') +
          ');'
      "
    >
      <template v-for="(n, index) in bubLoop">
        <div :key="index">
          <Bubbles :id="index" :slide="slideNum" />
        </div>
      </template>
    </div>

    <transition name="fade" appear>
      <div class="section" v-if="value === -1 && $route.path === '/'">
        <b-row>
          <b-col
            lg="4"
            md="8"
            offset-lg="2"
            offset-md="2"
            offset-sm="2"
            sm="8"
            cols="12"
          >
            <h1 class="title">
              Assess Your Data Sanitization Policies
            </h1>
            <p class="subText">
              <strong><em>Identify areas for improvement</em></strong>
            </p>
            <p class="text">
              Tell us about your organization’s current data sanitization
              policies and processes. In less than 5 minutes, you’ll get a
              personalized report on how you’re doing.
            </p>
            <p class="text">
              You’ll see how to increase data security, reduce your
              environmental impact, and cut your costs.
            </p>
            <p class="text surv">
              <b-img
                class="clock"
                :src="require('@/assets/img/clock.png')"
                alt="clock icon"
              />
              <strong>5-minutes</strong>
            </p>
            <b-button class="nB" @click="add">Complete the survey</b-button>
            <p class="priv">
              All responses are strictly confidential. Any personal data you
              share is subject to our
              <a href="https://www.blancco.com/privacy-policy/"
                >privacy policy.</a
              >
            </p>
          </b-col>
          <b-col class="mobHide" lg="6" md="0" sm="0" cols="0">
            <b-img
              class="laptop"
              :src="require('@/assets/img/Mac.png')"
              alt="a Mac being shredded"
            />
          </b-col>
        </b-row>
      </div>
    </transition>
    <b-container class="section" v-if="value > -1 && sent === false">
      <transition name="fade" appear>
        <template v-if="value <= 8">
          <b-row>
            <b-col offset-lg="3" lg="6">
              <div class="numb">
                <span class="thick">0{{ value + 1 }}</span
                ><span class="thin"> of 0{{ max }} questions</span>
              </div>
              <b-progress :value="value + 1" :max="max"></b-progress>
              <transition name="fade" appear>
                <div :key="which[0].id">
                  <div>
                    <h2 class="question">
                      {{ which[0].q }}
                    </h2>
                    <template v-for="(a, index) in which[0].answer">
                      <AnswerButton
                        :key="index"
                        @update="setAnswers"
                        :answers="a"
                        :value="value"
                        :a="aSet"
                      />
                    </template>
                  </div>
                  <div class="mobCentre">
                    <b-button class="mt-3 nB back" @click="minus"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.743"
                        height="9.984"
                        viewBox="0 0 16.743 9.984"
                        class="backA"
                      >
                        <path
                          id="Path_1038"
                          data-name="Path 1038"
                          d="M12.28-9.781a.751.751,0,0,0-1.06,0,.743.743,0,0,0-.22.53.743.743,0,0,0,.22.53l2.962,2.962H.75a.751.751,0,0,0-.75.75.75.75,0,0,0,.75.75H14.182L11.22-1.3a.744.744,0,0,0-.22.53.745.745,0,0,0,.22.531.747.747,0,0,0,.53.219.747.747,0,0,0,.53-.219l4.243-4.243a.744.744,0,0,0,.22-.53.747.747,0,0,0-.22-.531Z"
                          transform="translate(16.743 -0.016) rotate(180)"
                          fill="#fff"
                        /></svg
                      >Back</b-button
                    >
                    <b-button class="mt-3 nB" @click="add"
                      >{{ next()
                      }}<svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.743"
                        height="9.984"
                        viewBox="0 0 16.743 9.984"
                        class="nextA"
                      >
                        <path
                          id="Path_1147"
                          data-name="Path 1147"
                          d="M12.28-9.781a.751.751,0,0,0-1.06,0,.743.743,0,0,0-.22.53.743.743,0,0,0,.22.53l2.962,2.962H.75a.751.751,0,0,0-.75.75.75.75,0,0,0,.75.75H14.182L11.22-1.3a.744.744,0,0,0-.22.53.745.745,0,0,0,.22.531.747.747,0,0,0,.53.219.747.747,0,0,0,.53-.219l4.243-4.243a.744.744,0,0,0,.22-.53.747.747,0,0,0-.22-.531Z"
                          transform="translate(0 10)"
                          fill="#3b5eab"
                        /></svg
                    ></b-button>
                  </div>
                </div>
              </transition>
            </b-col>
          </b-row>
        </template>
      </transition>
      <transition name="fade" appear>
        <template v-if="value === 9 && sent === false && spin === false">
          <div>
            <b-row>
              <b-col lg="8" md="8" offset-lg="2" offset-md="2" cols="12">
                <div class="form">
                  <h2 class="resultTitle">
                    Get Your Full Report
                  </h2>
                  <!--    <p class="text">
                    Overall, your organization’s data sanitization policies and
                    processes are:
                  </p>
                    <Dial :score="pro" /> -->
                  <p class="text tf">
                    <template v-if="pro === 'Above Average'">
                      See where you’re excelling, and where there’s room for
                      improvement. Fill in the form to get a detailed breakdown
                      of how you’re doing, question by question. Gain insights
                      and actionable advice from the Blancco data erasure
                      experts and learn how to sanitize your end-of-life data
                      more securely and sustainably.
                    </template>
                    <template v-if="pro === 'In Line'">
                      See where you’re making strides, and where there’s room
                      for improvement. Fill in the form to get a detailed
                      breakdown of how you’re doing, question by question. Gain
                      insights and actionable advice from the Blancco data
                      erasure experts and learn how to sanitize your end-of-life
                      data more securely and sustainably.
                    </template>
                    <template v-if="pro === 'Below Average'">
                      Fill in the form to get your comprehensive report. It will
                      reveal where you’re performing well, and where there’s
                      room for improvement on sanitizing your end-of-life data
                      more securely and sustainably.
                    </template>
                  </p>
                  <b-form @submit="onSubmit">
                    <b-form-row>
                      <b-col lg="6" md="6" cols="12">
                        <b-form-group
                          id="input-group-1"
                          label="First Name*"
                          label-for="input-1"
                        >
                          <b-form-input
                            id="input-1"
                            v-model="form.first_name"
                            type="text"
                            placeholder="Enter First Name"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="6" md="6" cols="12">
                        <b-form-group
                          id="input-group-2"
                          label="Last Name*"
                          label-for="input-2"
                        >
                          <b-form-input
                            id="input-2"
                            v-model="form.last_name"
                            type="text"
                            placeholder="Last Name"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col lg="6" md="6" cols="12">
                        <b-form-group
                          id="input-group-3"
                          label="Company*"
                          label-for="input-3"
                        >
                          <b-form-input
                            id="input-1"
                            v-model="form.company"
                            type="text"
                            placeholder="Company"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="6" md="6" cols="12">
                        <b-form-group
                          id="input-group-4"
                          label="Business Email*"
                          label-for="input-4"
                        >
                          <b-form-input
                            id="input-4"
                            v-model="form.email"
                            type="email"
                            placeholder="Business Email"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col>
                        <b-form-checkbox v-model="form.privacy"
                          ><p>
                            Yes I would like to receive information regarding
                            Blancco products and services. I understand that I
                            can opt-out at any time.
                          </p>
                        </b-form-checkbox>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col>
                        <b-button type="submit" class="submit nB"
                          >Get my report</b-button
                        >
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col>
                        <p class="privText">
                          All your answers will be kept strictly confidential.
                          Blancco will not discuss, disclose, disseminate, or
                          provide access to any benchmark tool data and
                          identifiers to any third-party organizations. Any
                          personal data you provide is subject to
                          <a href="https://www.blancco.com/privacy-policy/"
                            >Blancco’s privacy policy</a
                          >
                          and will be solely processed in accordance with EU and
                          applicable national data privacy laws and regulations.
                          You, as a data subject, have the right to access,
                          rectify and cancel the personal data you have provided
                          to us. By accepting our terms, you are consenting to
                          the process and practices described in the
                          aforementioned privacy policy.
                        </p>
                      </b-col>
                    </b-form-row>
                  </b-form>
                </div>
              </b-col>
            </b-row>
          </div>
        </template>
        <template v-if="value === 9 && sent === false && spin === true">
          <div class="spinDiv">
            <b-spinner label="Spinning"></b-spinner>
          </div>
        </template>
      </transition>
    </b-container>
    <b-container v-if="sent === true" class="thank">
      <transition name="fade" appear>
        <template>
          <b-container>
            <div class="thank-you">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="78.465"
                viewBox="0 0 80 78.465"
              >
                <g id="tick" transform="translate(-21.945 -22.598)">
                  <path
                    id="Path_1292"
                    data-name="Path 1292"
                    d="M99.168,51.567l-7.741-4.733a2.45,2.45,0,0,1-.863-1.434l-.146-8.6a6.055,6.055,0,0,0-2.3-4.733A6.5,6.5,0,0,0,83.1,30.63l-8.889,1.288a1.366,1.366,0,0,1-1.434-.571l-5.881-6.6a6.6,6.6,0,0,0-9.746,0l-5.881,6.6a2.046,2.046,0,0,1-1.434.571L41.1,30.344a6.193,6.193,0,0,0-5.019,1.434,6.28,6.28,0,0,0-2.3,4.733l-.146,8.6a1.574,1.574,0,0,1-.863,1.434l-7.741,4.733a6.5,6.5,0,0,0-3.013,4.447,6.677,6.677,0,0,0,1.434,5.164l5.45,6.6a1.558,1.558,0,0,1,.286,1.58l-2.868,8.6a6.3,6.3,0,0,0,.571,5.3,6.5,6.5,0,0,0,4.447,3.013L39.8,87.425a1.894,1.894,0,0,1,1.288,1l3.153,8.318a6.85,6.85,0,0,0,3.87,3.87,5.935,5.935,0,0,0,5.3-.431l7.6-4.447a1.832,1.832,0,0,1,1.719,0l7.6,4.447a6.06,6.06,0,0,0,5.45.431,6.45,6.45,0,0,0,3.87-3.87l3.153-8.318a1.381,1.381,0,0,1,1.288-1l8.458-1.434A6.49,6.49,0,0,0,97,82.978a6.281,6.281,0,0,0,.571-5.3L94.7,69.07a1.56,1.56,0,0,1,.286-1.58l5.45-6.6a6.677,6.677,0,0,0,1.434-5.164,5.277,5.277,0,0,0-2.705-4.162Zm-2.011,6.452-5.59,6.738a6.391,6.391,0,0,0-1.148,6.167l2.868,8.6a1.577,1.577,0,0,1-.146,1.434,1.545,1.545,0,0,1-1.148.863l-8.458,1.434a6.391,6.391,0,0,0-4.873,4.016l-3.153,8.318a2.2,2.2,0,0,1-1,1,1.737,1.737,0,0,1-1.434-.146L65.472,92a6.474,6.474,0,0,0-6.452,0l-7.6,4.447a1.782,1.782,0,0,1-1.434.146,1.666,1.666,0,0,1-1-1l-3.153-8.318a6.765,6.765,0,0,0-4.873-4.156L32.5,81.684a1.533,1.533,0,0,1-1.148-.863,1.5,1.5,0,0,1-.146-1.434l2.868-8.6a6.391,6.391,0,0,0-1.148-6.167l-5.45-6.6a1.9,1.9,0,0,1-.431-1.288,1.321,1.321,0,0,1,.717-1.148L35.5,50.85a6.257,6.257,0,0,0,3.013-5.3l.146-8.6c0-.717.431-1,.571-1.288a1.72,1.72,0,0,1,1.148-.431h.286l8.889,1.288a6.273,6.273,0,0,0,5.735-2.151l5.881-6.6a1.738,1.738,0,0,1,2.577,0l5.881,6.6a6.634,6.634,0,0,0,5.735,2.151l8.889-1.288a1.107,1.107,0,0,1,1.288.431,1.782,1.782,0,0,1,.571,1.288l.146,8.6a6.256,6.256,0,0,0,3.013,5.3l7.741,4.733a1.321,1.321,0,0,1,.717,1.148c-.28.286-.14.857-.571,1.288Z"
                    fill="#fff"
                  />
                  <path
                    id="Path_1293"
                    data-name="Path 1293"
                    d="M68.041,41.861,49.4,63.223l-7.169-8.032a2.335,2.335,0,1,0-3.445,3.153l9.035,10.037a2.1,2.1,0,0,0,1.719.717,2.037,2.037,0,0,0,1.72-.863L71.766,44.862a2.3,2.3,0,0,0-.286-3.3,2.479,2.479,0,0,0-3.439.3Z"
                    transform="translate(7.036 7.986)"
                    fill="#fff"
                  />
                </g>
              </svg>
              <h2 class="thanks">
                Thanks! Your Custom Report Is on Its Way.
              </h2>
              <p class="thanksText">
                You can expect it in your inbox soon. In the meantime, here’s
                some further guidance on adopting more
                <a
                  href="https://www.blancco.com/company/sustainability/?utm_source=benchmarking-tool-report&utm_medium=thank-you-page&utm_content=sustainability-web-page&utm_campaign=publicsector"
                  >sustainable data sanitization practices.</a
                >
              </p>
              <!-- For Testing Only 
              <b-button style="color:#3b5eab!important ;" :href="pdfURL()">
                Temp button for PDF
              </b-button>
              -->
            </div>
          </b-container>
        </template>
      </transition>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import questions from "@/assets/js/questions";
//import Dial from "@/components/dials.vue";
import Bubbles from "@/components/bubbles.vue";
import AnswerButton from "@/components/answerButton.vue";
export default {
  name: "Home",
  components: {
    //   Dial,
    Bubbles,
    AnswerButton,
  },
  data: function() {
    return {
      value: -1,
      max: 9,
      aSet: "",
      bubLoop: 4,
      questions: questions,
      pro: "",
      sent: false,
      spin: false,
      change: "",
      form: {
        first_name: "",
        last_name: "",
        company: "",
        email: "",
        privacy: false,
      },
      answer: [[], [], [], [], [], [], [], [], []],
      answerText: [[], [], [], [], [], [], [], [], []],
      pdf: "",
    };
  },
  methods: {
    score() {
      var int = [
        this.answer[0],
        this.answer[1],
        this.answer[2],
        this.answer[3],
        this.answer[4],
        this.answer[5],
        this.answer[6],
        this.answer[7],
        this.answer[8],
      ];
      var sum = int.reduce((acc, item) => acc + item, 0);
      if (sum >= 8 && sum <= 14) {
        this.pro = "Below Average";
      } else if (sum >= 15 && sum <= 20) {
        this.pro = "In Line";
      } else if (sum >= 21 && sum <= 27) {
        this.pro = "Above Average";
      }
    },
    add() {
      if (this.value >= 0) {
        if (this.answer[this.value].length === 0) {
          this.value;
          this.scrollToTop();
        } else {
          this.value = this.value + 1;
          this.aSet = "";
          this.scrollToTop();
          this.$gtag.event("Click Next", {
            event_category: "engagement",
            event_label: "page - " + this.value,
            value: 0,
          });
        }
      } else if (this.value < 0) {
        this.value = this.value + 1;
        this.aSet = "";
        this.scrollToTop();
        this.$gtag.event("Click Next", {
          event_category: "engagement",
          event_label: "page - start",
          value: 0,
        });
      } else {
        this.value = this.value + 1;
        this.aSet = "";
        this.scrollToTop();
      }
    },
    minus() {
      this.value = this.value - 1;
    },
    pdfURL() {
      return process.env.VUE_APP_URL + "web/temp/" + this.pdf + ".pdf";
    },
    next(text) {
      if (this.value <= 7) {
        text = "Next";
      } else {
        text = "Get results";
      }
      return text;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    setAnswers(payload) {
      this.answer[this.value] = payload.newScore;
      this.answerText[this.value] = payload.newAnswer;
      this.aSet = payload.newAnswer;
      sessionStorage["answer_" + this.value] = payload.newAnswer;
    },
    onSubmit(e) {
      e.preventDefault();
      this.spin = true;
      const formData = new FormData();
      formData.append("b", this.form.company);
      formData.append("a", this.answer);
      formData.append("privacy", this.form.privacy);
      formData.append("email", this.form.email);
      formData.append("survey1", this.answerText[0]);
      formData.append("survey2", this.answerText[1]);
      formData.append("survey3", this.answerText[2]);
      formData.append("survey4", this.answerText[3]);
      formData.append("survey5", this.answerText[4]);
      formData.append("survey6", this.answerText[5]);
      formData.append("survey7", this.answerText[6]);
      formData.append("survey8", this.answerText[7]);
      formData.append("survey9", this.answerText[8]);
      formData.append("action", "createPDF");
      axios
        .post(process.env.VUE_APP_BACK, formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          this.pdf = response.data.result;
          this.sent = true;
          this.spin = false;
        });
    },
  },
  computed: {
    which() {
      var q = this.questions.filter((whichQ) => whichQ.id === this.value);
      return q;
    },
    slideNum(num) {
      if (this.value === -1) {
        num = "first";
      } else if (this.value === 0) {
        num = "second";
      } else if (this.value === 1) {
        num = "third";
      } else if (this.value === 2) {
        num = "fourth";
      } else if (this.value === 3) {
        num = "fifth";
      } else if (this.value === 4) {
        num = "sixth";
      } else if (this.value === 5) {
        num = "seventh";
      } else if (this.value === 6) {
        num = "eighth";
      } else if (this.value === 7) {
        num = "nineth";
      } else if (this.value === 8) {
        num = "tenth";
      } else if (this.value === 9) {
        num = "eleventh";
        this.score();
      } else if (this.value === 10) {
        num = "thank-you";
      }
      return num;
    },
  },
  mounted() {
    sessionStorage.clear();
  },
};
</script>

<style scoped>
.bubbles {
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 100vh;
  position: absolute;
  top: 0;
  width: 100%;
  min-width: 1900px;
  opacity: 0.8;
  overflow: hidden;
  background-position-y: 85px;
}
.laptop {
  width: 100%;
  max-width: 949px;
}
.section {
  padding: 140px 0;
  z-index: 5;

  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
.title {
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  font: normal normal 900 48px/60px "aktiv-grotesk";
  letter-spacing: -0.49px;
}
.thanks {
  text-align: center;
  font: normal normal 400 44px/56px "aktiv-grotesk";
  letter-spacing: 0px;
  color: #fff;
  padding: 25px 0 16px;
}
.thanksText {
  text-align: center;
  font: normal normal normal 16px/32px "aktiv-grotesk";
  letter-spacing: 0px;
  color: #ffffff;
}
a {
  color: #fff !important;
  text-decoration: underline !important;
}

.text {
  text-align: left;
  font: normal normal normal 20px/32px "aktiv-grotesk";
  letter-spacing: 0px;
  color: #ffffff;
}
.subText {
  text-align: left;
  font: normal normal normal 24px/32px "aktiv-grotesk";
  letter-spacing: 0px;
  color: #ffffff;
}
.clock {
  width: 22px;
  margin-right: 10px;
}
.surv {
  padding-bottom: 30px;
}
.nB {
  text-align: left;
  font: normal normal 400 16px/21px "aktiv-grotesk";
  letter-spacing: 0px;
  color: #3b5eab;
  background-color: #fff;
  border-radius: 10px;
  padding: 11px 25px;
  border-color: #fff;
}
.nB:active,
.nB:focus,
.nB:hover {
  color: #3b5eab !important;
  background-color: #fff !important;
  border-color: #fff !important;
  box-shadow: none;
}

.nB.back {
  background-color: transparent !important;
  color: #fff !important;
  padding-left: 0 !important;
  border: none !important;
}
.nB.back:hover {
  background-color: transparent !important;
  color: #fff !important;
  padding-left: 0 !important;
  border: none !important;
}
.backA {
  margin-right: 8px;
}
.nextA {
  margin-left: 8px;
}

.numb {
  color: #fff;
  text-align: left;
  font: normal normal normal 20px/20px "aktiv-grotesk";
  letter-spacing: -0.01px;
}
.thick {
  font-weight: bold;
}
.thin {
  opacity: 0.9;
}
.progress {
  width: 31%;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 4px;
  height: 8px;
  margin-top: 5px;
  margin-bottom: 25px;
}
::v-deep .progress-bar {
  color: transparent;
  background-color: #2eb77f;
  border-radius: 10px;
}
.question {
  text-align: left;
  font: normal normal 400 38px/48px "aktiv-grotesk";
  letter-spacing: -0.02px;
  color: #ffffff;
}

.form {
  text-align: left;
  color: #fff;
}
::v-deep input {
  border-radius: 4px;
}

.resultTitle {
  font: normal normal 400 44px/56px "aktiv-grotesk";
}
.tf {
  max-width: 792px;
  margin: auto;
  padding: 10px 0 40px;
  font: normal normal 400 20px/32px "aktiv-grotesk";
}
.icon {
  margin: 0 10px;
}
.form-group {
  text-align: left;
}
#checkboxes-1 {
  text-align: left;
}
::v-deep .custom-control-label {
  letter-spacing: 0px;
  font-size: 12px;
}
.privText {
  letter-spacing: 0px;
  font-size: 12px;
}
.submit {
  float: left;
  margin: 25px 0;
}
.thank-you {
  padding-top: 225px;
  text-align: center;
  max-width: 635px;
  margin: auto;
}
.thank {
  z-index: 5;
  position: absolute;
  left: 0;
  right: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-active {
  transition-delay: 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.spinDiv {
  text-align: center;
  padding: 250px 0;
}

.spinner-border {
  width: 5rem;
  height: 5rem;
  border: 0.55em solid rgb(51, 149, 171);
  border-right-color: transparent;
}
.priv {
  padding-top: 50px;
  color: #fff;
  font: normal normal normal 12px/16px "aktiv-grotesk";
}
@media only screen and (max-width: 990px) {
  .mobHide {
    display: none;
  }
  .bubbles {
    height: 107vh;
  }
}
@media only screen and (max-width: 500px) {
  .section {
    padding: 100px 15px;
  }
  .mobCentre {
    text-align: center;
  }
  .thank-you {
    padding-top: 155px;
  }
  .progress {
    width: 43%;
  }
  .rankSmall {
    display: inline-flex;
  }
}
</style>
