<template>
  <b-button
    class="answer"
    :class="{ 'set': currentAnswer === true }"
    @click="picked(answers.s, answers.a)"
    >{{ answers.a }}</b-button
  >
</template>

<script>
export default {
  props: ["answers", "value", "a"],
  methods: {
    picked: function(score, answer) {
      this.$emit("update", { newScore: score, newAnswer: answer });
    },
  },
  computed: {
    currentAnswer() {
      if(this.a === ""){
        var b = sessionStorage['answer_' + this.value]
        if(b === this.answers.a){
          var c = true
        }
        else {
          c = false
        }
      }
      else if (this.a === this.answers.a){
        c = true
      }
      else{
        c = false
      }
      return c;
    },
  },
};
</script>

<style scoped>
.answer.set {
  color: #3b5eab !important;
  background-color: #fff !important;
  border: 2px solid #ffffff !important;
}
.answer {
  display: block;
  border: 2px solid #ffffff;
  border-radius: 12px;
  background-color: transparent;
  color: #fff;
  width: 100%;
  margin: 20px 0;
  text-align: left;
  font: normal normal normal 16px/32px "aktiv-grotesk";
  letter-spacing: -0.01px;
}
.answer:hover {
  background-color: #fff!important;
  color: #3b5eab!important;
  border: 2px solid #ffffff !important;
  box-shadow: none;
}
@media only screen and (max-width: 990px) {
  .answer:hover{
     background-color: transparent!important;
     color: #fff!important;
  }
  .answer.set:hover{
  color: #3b5eab !important;
  background-color: #fff !important;
  }
}
</style>
