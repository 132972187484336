<template>
  <div>
    <template v-if="question === 1">
      <h5 class="question Qone">
        1. Data protection regulations, directives and guidelines
      </h5>
    </template>
    <template v-if="question === 2">
      <h5 class="question Qtwo">
        2. Your end-of-life data sanitization process
      </h5>
    </template>
    <template v-if="question === 3">
      <h5 class="question Qthree">
        3. The number of SSDs you physically destroy annually
      </h5>
    </template>
    <template v-if="question === 4">
      <h5 class="question Qfour">
        4. The cost of destroying and replacing SSDs
      </h5>
    </template>
    <template v-if="question === 5">
      <h5 class="question Qfive">
        5. Policy restrictions
      </h5>
    </template>
    <template v-if="question === 6">
      <h5 class="question Qsix">
        6. Policy reviews (and updates)
      </h5>
    </template>
    <template v-if="question === 7">
      <h5 class="question Qseven">
        7. Your environmental impact
      </h5>
    </template>
    <template v-if="question === 8">
      <h5 class="question Qeight">
        8. Data breaches
      </h5>
    </template>
    <template v-if="question === 9">
      <h5 class="question Qnine">
        9. Yor physical destruction processes
      </h5>
    </template>
  </div>
</template>

<script>
export default {
  name: "question",
  props: ["question"],
};
</script>

<style scoped>
.question {
  background: transparent linear-gradient(92deg, #253a6b 0%, #3b5eab 100%) 0% 0%
    no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font: normal normal 500 28px/39px "aktiv-grotesk";
}
</style>
