const data = [
  {
    id:0,
    q: "How well does your organization know national and global data regulations, directives, and guidelines?",
    answer: [
      {
        a: "Very well, in detail",
        s: 3
      },
      {
        a: "Well, but not in detail",
        s: 2
      },
      {
        a: "Not very well",
        s: 1
      },
      {
        a: "Not at all",
        s: 1
      },
    ],
  },
  {
    id:1,
    q: "Which of the following processes do you carry out to sanitize SSDs?",
    answer: [
      {
        a: "Software-based erasure and reuse",
        s: 3
      },
      {
        a: "Cryptographic erasure / encryption",
        s: 2
      },
      {
        a: "Reformatting",
        s: 2
      },
      {
        a: "Software-based erasure and physical destruction",
        s: 2
      },
      {
        a: "Physical destruction of only the SSD",
        s: 1
      },
      {
        a: "Physical destruction of the IT asset, including the SSD",
        s: 1
      },
    ],
  },
  {
    id:2,
    q: "How many SSDs do you destroy every year?",
    answer: [
      {
        a: "Fewer than 1,000",
        s: 3
      },
      {
        a: "1,001 to 2,000",
        s: 2
      },
      {
        a: "More than 2,000",
        s: 1
      },
    ],
  },
  {
    id:3,
    q: "How much do you spend each year replacing SSDs that have been destroyed? ",
    answer: [
      {
        a: "Less than $50,000 USD",
        s: 3
      },
      {
        a: "$50,001 to $100,000 USD",
        s: 2
      },
      {
        a: "More than $100,000 USD",
        s: 1
      },
    ],
  },
  {
    id:4,
    q: "Does your internal policy mandate the physical destruction of SSDs that contain classified data?",
    answer: [
      {
        a: "Yes",
        s: 1
      },
      {
        a: "No",
        s: 3
      },
    ],
  },
  {
    id:5,
    q: "When did your organization last review its SSD data sanitization policies?",
    answer: [
      {
        a: "Less than six months ago",
        s: 3
      },
      {
        a: "Six to 12 months ago",
        s: 2
      },
      {
        a: "Over a year ago",
        s: 1
      },
      {
        a: "I don’t know",
        s: 1
      },
      {
        a: "Never",
        s: 1
      },
    ],
  },
  {
    id:6,
    q: "Is there a plan within your organization to reduce the environmental impact caused by destroying IT equipment?",
    answer: [
      {
        a: "Yes, our plan has been defined and is being implemented",
        s: 3
      },
      {
        a: "Yes, we have a plan, but we haven’t implemented it",
        s: 2
      },
      {
        a: "No, we have no plans",
        s: 1
      },
    ],
  },
  {
    id:7,
    q: "Has your data ever been breached from end-of-life devices? ",
    answer: [
      {
        a: "Yes",
        s: 1
      },
      {
        a: "No",
        s: 3
      },
    ],
  },
  {
    id:8,
    q: "Do you have full confidence in your organization’s physical destruction process?",
    answer: [
      {
        a: "Yes ",
        s: 3
      },
      {
        a: "No ",
        s: 1
      },
    ],
  },
]
export default data
