<template>
  <div>
    <template v-if="id === 0">
      <svg
        class="two"
        :class="slide"
        xmlns="http://www.w3.org/2000/svg"
        width="222.626"
        height="224.809"
        viewBox="0 0 222.626 224.809"
      >
        <path
          id="Path_3315"
          data-name="Path 3315"
          d="M151.708-429H70.957C31.742-429,0-396.943,0-357.354v81.538c0,39.569,31.742,71.626,70.957,71.626h80.751c39.156,0,70.918-32.057,70.918-71.626v-81.538c0-39.589-31.762-71.646-70.918-71.646"
          transform="translate(0 429)"
          fill="#3395ab"
          fill-rule="evenodd"
        /></svg
    ></template>
    <template v-if="id === 1">
      <svg
        class="one"
        :class="slide"
        xmlns="http://www.w3.org/2000/svg"
        width="210.472"
        height="212.544"
        viewBox="0 0 210.472 212.544"
      >
        <path
          id="Path_3314"
          data-name="Path 3314"
          d="M176.157-425.181H97.087c-36.292,0-65.709,29.7-65.709,66.349v79.862c0,36.646,29.418,66.333,65.709,66.333h79.07c36.275,0,65.693-29.687,65.693-66.333v-79.862c0-36.645-29.418-66.349-65.693-66.349"
          transform="translate(-31.378 425.181)"
          fill="#3b5eab"
          fill-rule="evenodd"
        /></svg
    ></template>
    <template v-if="id === 2"
      ><svg
        class="zero"
        :class="slide"
        xmlns="http://www.w3.org/2000/svg"
        width="328.112"
        height="331.304"
        viewBox="0 0 328.112 331.304"
      >
        <path
          id="Path_1309"
          data-name="Path 1309"
          d="M254.328-425.181H132.565c-55.887,0-101.188,45.742-101.188,102.174v122.982c0,56.432,45.3,102.148,101.188,102.148H254.328c55.861,0,101.162-45.716,101.162-102.148V-323.008c0-56.431-45.3-102.174-101.162-102.174"
          transform="translate(-29.378 427.181)"
          fill="none"
          stroke="#fff"
          stroke-width="4"
          fill-rule="evenodd"
        /></svg
    ></template>
    <template v-if="id === 3"
      ><svg
        class="three"
        :class="slide"
        xmlns="http://www.w3.org/2000/svg"
        width="336.475"
        height="339.749"
        viewBox="0 0 336.475 339.749"
      >
        <path
          id="Path_3316"
          data-name="Path 3316"
          d="M260.081-425.181h-124.9c-57.329,0-103.8,46.922-103.8,104.81v126.156c0,57.888,46.47,104.784,103.8,104.784h124.9c57.3,0,103.772-46.9,103.772-104.784V-320.371c0-57.888-46.47-104.81-103.772-104.81"
          transform="translate(-29.378 427.181)"
          fill="none"
          stroke="#fff"
          stroke-width="4"
          fill-rule="evenodd"
        />
      </svg>
    </template>
  </div>
</template>

<script>
export default {
  name: "bubbles",
  props: ["id", "slide"],
};
</script>

<style scoped>
svg {
  position: absolute;
}
.zero {
  top: -12%;
  left: -12.5%;
  width: 324px;
  height: 327px;
  animation-name: zero-move;
  animation-duration: 120s;
  animation-iteration-count: infinite;
  z-index: 1;
will-change: transform, animation;
}
@keyframes zero-move {
  0% {
    top: -12%;
    left: -12.5%;
  }
  10% {
    top: -3%;
    left: -1.5%;
  }
  20% {
    top: -3%;
    left: -10%;
  }
  30% {
    top: 0%;
    left: -5%;
  }
  40% {
    top: -12.5%;
    left: -8.5%;
  }
  50% {
    top: -3%;
    left: -4.5%;
  }
  60% {
    top: -3%;
    left: -4.5%;
  }
  70% {
    top: -3%;
    left: -7.5%;
  }
  80% {
    top: 12%;
    left: -8.5%;
  }
  90% {
    top: 5%;
    left: -9%;
  }
  100% {
    top: -12%;
    left: -12.5%;
  }
}

.one {
  left: 82%;
  top: 60%;
  width: 133px;
  height: 135px;
  animation-name: one-move;
  animation-duration: 120s;
  animation-iteration-count: infinite;
  z-index: 1;
will-change: transform, animation;
}
@keyframes one-move {
  0% {
  left: 82%;
  top: 60%;
    width: 133px;
    height: 135px;
  }
  10% {
    left: 84%;
    top: 74%;
    width: 210px;
    height: 213px;
  }
  20% {
    left: 83%;
    top: 42.5%;
    width: 167px;
    height: 168px;
  }
  30% {
    left: 83%;
    top: 63%;
    width: 213px;
    height: 215px;
  }
  40% {
    left: 82%;
    top: 68%;
    width: 202px;
    height: 204px;
  }
  50% {
    left: 80%;
    top: 66%;
    width: 197px;
    height: 199px;
  }
  60% {
    left: 88%;
    top: 74%;
    width: 133px;
    height: 135px;
  }
  70% {
    left: 88%;
    top: 72%;
    width: 210px;
    height: 212px;
  }
  80% {
    left: 82%;
    top: 64%;
    width: 133px;
    height: 135px;
  }
  90% {
    left: 84%;
    top: 85%;
    width: 133px;
    height: 135px;
  }
  100% {
  left: 82%;
  top: 60%;
    width: 133px;
    height: 135px;
  }
}
.two {
    left: .5%;
    top: 15%;
  width: 200px;
  height: 202px;
  animation-name: two-move;
  animation-duration: 120s;
  animation-iteration-count: infinite;
  z-index: 1;
will-change: transform, animation;
}
@keyframes two-move {
  0% {
    left: .5%;
    top: 15%;
    width: 200px;
    height: 202px;
  }
  10% {
    left: 8.5%;
    top: 23%;
    width: 223px;
    height: 225px;
  }
  20% {
    left: 5.5%;
    top: 15%;
    width: 157px;
    height: 158px;
  }
  30% {
    left: 8.5%;
    top: 25%;
    width: 162px;
    height: 164px;
  }
  40% {
    left: 5.5%;
    top: 13%;
    width: 226px;
    height: 229px;
  }
  50% {
    left: 10%;
    top: 15%;
    width: 200px;
    height: 202px;
  }
  60% {
    left: 8.5%;
    top: 12%;
    width: 167px;
    height: 168px;
  }
  70% {
    left: 6%;
    top: 12%;
    width: 200px;
    height: 202px;
  }
  80% {
    left: 4.5%;
    top: 1%;
    width: 156px;
    height: 157px;
  }
  90% {
    left: 4.5%;
    top: 35%;
    width: 156px;
    height: 157px;
  }
  100% {
    left: .5%;
    top: 15%;
    width: 200px;
    height: 202px;
  }
}
.three {
  width: 324px;
  height: 327px;
  left: 70%;
  top: 50%;
  animation-name: three-move;
  animation-duration: 120s;
  animation-iteration-count: infinite;
  z-index: 1;
will-change: transform, animation;
}
@keyframes three-move {
  0% {
    left: 70%;
    top: 50%;
  }
  10% {
    left: 70%;
    top: 53%;
  }
  20% {
    left: 76%;
    top: 53%;
  }
  30% {
    left: 72%;
    top: 54%;
  }
  40% {
    left: 70%;
    top: 48%;
  }
  50% {
    left: 70%;
    top: 45%;
  }
  60% {
    left: 73%;
    top: 53%;
  }
  70% {
    left: 75%;
    top: 51%;
  }
  80% {
    left: 70%;
    top: 57%;
  }
  90% {
    left: 72%;
    top: 65%;
  }
  100% {
    left: 70%;
    top: 50%;
  }
}
.zero.pdf {
  top: -109px !important;
  left: -103.03263092041016px !important;
  width: 324px !important;
  height: 327px !important;
}
.one.pdf {
  top: 1441px !important;
  left: 821px !important;
  width: 210px !important;
  height: 213px !important;
}
.two.pdf {
  top: 146px !important;
  left: 143px !important;
  width: 223px !important;
  height: 225px !important;
}
.three.pdf {
  top: 1043px !important;
  left: 311px !important;
  width: 620px !important;
  height: 626px !important;
      z-index: 0;
}
@media only screen and (max-width: 425px) {
  .zero {
    top: -12%;
    left: 15.5%;
    width: 324px;
    height: 327px;
    animation: none;
  }
  .two {
    left: 11.5%;
    top: 15%;
    width: 200px;
    height: 202px;
    animation: none;
  }
}
</style>
