import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import JQuery from "jquery";
import VueGtag from "vue-gtag";


Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueGtag, {
  config: { id: "UA-236047-3" },
  includes: [
    { id: 'UA-236047-3' }
  ]
}, router);

new Vue({
  router,
  store,
  axios,
  JQuery,
  render: (h) => h(App),
}).$mount("#app");
