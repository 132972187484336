<template>
  <div>
    <template v-if="question === 1">
      <p class="didYou">
        <span class="didBold">Did you know?</span> In the UK, almost all
        respondents knew the EU GDPR in detail. However, only 47 percent stated
        they felt the same way about the UK Data Protection Act (DPA) 2018,
        which works alongside the UK GDPR.
      </p>
    </template>
    <template v-if="question === 2">
      <p class="didYou">
        <span class="didBold">Did you know?</span> Sanitizing drives through
        reformatting can still leave data vulnerable during transport or
        storage, and much of the data on reformatted drives and devices can be
        recovered with forensic tools that are easily accessible online.
      </p>
    </template>
    <template v-if="question === 3">
      <p class="didYou">
        <span class="didBold">Did you know?</span> Altogether, our respondents
        spend up to $17 million USD each year destroying SSD-based IT assets and
        another $40 million USD replacing them.
      </p>
      <p class="didYou extra">
        <span class="didBold"
          >The Cost to Destroy and Repurchase IT Assets, at a Glance
        </span>
      </p>
      <p class="didYou">
        Get a clear view of the costs tied to SSD destruction and repurchase
        worldwide. Numbers are as reported or calculated per annum, per
        respondent, unless otherwise noted.
      </p>
      <div class="extra">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                <b-img
                  class="map"
                  :src="require('@/assets/img/global.png')"
                /><br />Avg/Total
              </th>
              <th>
                <b-img
                  class="map"
                  :src="require('@/assets/img/aus.png')"
                /><br />Austraila
              </th>
              <th>
                <b-img
                  class="map"
                  :src="require('@/assets/img/can.png')"
                /><br />Canada
              </th>
              <th>
                <b-img
                  class="map"
                  :src="require('@/assets/img/fr.png')"
                /><br />France
              </th>
              <th>
                <b-img
                  class="map"
                  :src="require('@/assets/img/du.png')"
                /><br />Germany
              </th>
              <th>
                <b-img
                  class="map"
                  :src="require('@/assets/img/ind.png')"
                /><br />India
              </th>
              <th>
                <b-img
                  class="map"
                  :src="require('@/assets/img/jap.png')"
                /><br />Japan
              </th>
              <th>
                <b-img
                  class="map"
                  :src="require('@/assets/img/sing.png')"
                /><br />Singapore
              </th>
              <th>
                <b-img
                  class="map"
                  :src="require('@/assets/img/uk.png')"
                /><br />UK
              </th>
              <th>
                <b-img
                  class="map"
                  :src="require('@/assets/img/us.png')"
                /><br />US
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>All respondents</td>
              <td>596</td>
              <td>60</td>
              <td>50</td>
              <td>70</td>
              <td>70</td>
              <td>70</td>
              <td>60</td>
              <td>36</td>
              <td>70</td>
              <td>110</td>
            </tr>
            <tr>
              <td>Avg yearly number of SSDs reported destroyed</td>
              <td>1,433</td>
              <td>1,348</td>
              <td>1,385</td>
              <td>1,748</td>
              <td>1,506</td>
              <td>1,456</td>
              <td>1,331</td>
              <td>1,292</td>
              <td>1,478</td>
              <td>1,316</td>
            </tr>
            <tr>
              <td>Destruction costs at $15-20 per SSD</td>
              <td>$21,495 - $28,660</td>
              <td>$20,220 - $26,960</td>
              <td>$20,775 - $27,700</td>
              <td>$26,220 - $34,960</td>
              <td>$22,590 - $30,120</td>
              <td>$21,840 - $29,120</td>
              <td>$19,965 - $26,620</td>
              <td>$19,380 - $25,840</td>
              <td>$22,170 - $29,560</td>
              <td>$19,740 - $26,320</td>
            </tr>
            <tr>
              <td>
                Avg yearly destruction costs in millions of USD for all
                respondents
              </td>
              <td>$12.8M - $17.0M</td>
              <td>$1.2.8M - $1.6M</td>
              <td>$1.0M - $1.4M</td>
              <td>$1.8M - $2.4M</td>
              <td>$1.6M - $2.1M</td>
              <td>$1.5M - $2.0M</td>
              <td>$1.1M - $1.6M</td>
              <td>$0.7M - $0.9M</td>
              <td>$1.6M - $2.0M</td>
              <td>$2.2M - $2.9M</td>
            </tr>
            <tr>
              <td>Avg yearly cost of new SSDs</td>
              <td>$65,235</td>
              <td>$58,334</td>
              <td>$65,100</td>
              <td>$66,857</td>
              <td>$70,715</td>
              <td>$49,000</td>
              <td>$51,500</td>
              <td>$51,945</td>
              <td>$68,786</td>
              <td>$84,455</td>
            </tr>
            <tr>
              <td>Avg yearly cost of new SSDs for all respondents</td>
              <td>$39.1M</td>
              <td>$3.5M</td>
              <td>$3.3M</td>
              <td>$4.7M</td>
              <td>$5.0M</td>
              <td>$3.4M</td>
              <td>$3.0M</td>
              <td>$1.8M</td>
              <td>$4.8M</td>
              <td>$9.3M</td>
            </tr>
            <tr>
              <td>TOTAL cost for all respondents to destroy and replace</td>
              <td>$51.9M - $56.1M</td>
              <td>$4.7M  -  $5.1M</td>
              <td>$4.3M  -  $4.6M</td>
              <td>$6.5M  -  $7.1M</td>
              <td>$6.5M  -  $7.0M</td>
              <td>$5.0M  -  $5.5M</td>
              <td>$4.2M  -  $4.7M</td>
              <td>$2.5M  -  $2.7M</td>
              <td>$6.4M  -  $6.9M</td>
              <td>$11.5M - $12.2M</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-if="question === 4">
      <p class="didYou dFour">
        <span class="didBold">Did you know?</span> Our research revealed that 38
        percent of respondents (wrongly) believe device destruction to be
        cheaper than other methods.
      </p>
    </template>
    <template v-if="question === 5">
      <p class="didYou dFive">
        <span class="didBold">Did you know?</span> Physical destruction of SSDs
        is incorrectly considered more secure than other data sanitization
        solutions by a whopping 46 percent of respondents globally. This is no
        doubt a reason why policies are falling short too.
      </p>
    </template>
    <template v-if="question === 6">
      <p class="didYou dSix">
        <span class="didBold">Did you know?</span> There are two main drivers
        behind physical destruction of SSDs: a lack of awareness of alternate
        device sanitization practices and older agency policies. Many older
        policies do not account for data sanitization technology that provides
        purge-level data security without actual physical destruction.
      </p>
    </template>
    <template v-if="question === 7">
      <p class="didYou dSeven">
        <span class="didBold">Did you know?</span> Global e-waste is projected
        to nearly double by 2030.
      </p>
      <b-row>
        <b-col lg="6">
          <p class="didYou didBold" style="padding-top:48px;">
            Organizations with environmental impact in mind
          </p>
          <b-img
            class="gOne"
            :src="require('@/assets/img/Graph_1.png')"
            alt="Pie chart showing 93% of respondents have defined plans"
          />
        </b-col>
        <b-col lg="6"> </b-col>
      </b-row>
    </template>
    <template v-if="question === 8">
      <p class="didYou dEight">
        <span class="didBold">Did you know?</span> Making sure
        your data is irreversibly erased is no small matter. Between 37 and 45
        percent of our respondents’ drives are sent offsite for physical
        destruction, potentially exposing them to data breaches during
        transport, storage, and processing.
      </p>
      <b-row>
        <b-col>
          <p class="didYou didBold" style="padding-top:48px;">
            The average cost of a breach
          </p>
          <b-img :src="require('@/assets/img/Graph.png')" />
        </b-col>
      </b-row>
    </template>
    <template v-if="question === 9">
      <p class="didYou dNine">
        <span class="didBold">Did you know?</span> Almost a
        quarter of public sector organizations (22 percent) are unaware of
        alternative methods of sanitization, such as software-based data
        erasure.
      </p>
      <br />
      <p class="didYou didBold" style="padding-top:48px;">
        Organizations use multiple methods to sanitize their data, and most use
        more than one
      </p>
      <b-row>
        <b-col>
          <b-img class="sStat" :src="require('@/assets/img/Stat.png')" />
        </b-col>
        <b-col>
          <b-img :src="require('@/assets/img/Stat2.png')" />
        </b-col>
        <b-col>
          <b-img class="sStat" :src="require('@/assets/img/Stat3.png')" />
        </b-col>
      </b-row>
      <br />
      <h5 class="title">
        Trust Blancco to Securely Erase Even Highly Classified Data
      </h5>
      <p class="didYou">
        Organizations depend on Blancco’s software to erase millions of IT
        assets each year, protecting end-of-life data from unauthorized access.
        Tested, certified, approved, and recommended by 13+ governing bodies and
        leading organizations around the world (including laboratory tests
        attempting data recovery), our data erasure software is proven to make
        sure data is completely removed, permanently. With Blancco, you can
        increase data security at every step along a device’s decommissioning,
        then confidently and securely redeploy IT assets without fear of data
        compromise.
      </p>
      <p class="didYou">
        <span class="didBold">
          Minimize Data Breaches & Ensure Compliance
        </span>
        <br />
        Blancco’s data erasure software is 100-percent effective in making data
        inaccessible. Each asset is logged, erasures are verified and certified,
        and digitally signed certificates of erasure are centrally stored to
        ease auditing. By securely erasing all data from end-of-life devices,
        you prevent sensitive data from being breached right from the start,
        ensuring compliance with globally notable data protection regulations
        like HIPAA, PCI-DSS, GDPR and more. You’ll also abide by data
        sanitization processes outlined within government-specific standards
        like NIST SP 800-88r1, DoD 5220.22, BSI, NCSC, and others around the
        world.
      </p>
      <p class="didYou">
        <span class="didBold">
          Cut Costs Two Ways
        </span>
        <br />
        Blancco provides secure, compliant, and automated solutions that
        accelerate your transition to better data sanitization practices. By
        employing data erasure and reuse, you can save tens of thousands in
        drive destruction and replacement costs, get more value from your IT
        investment, and provide an example of what it means to use public funds
        efficiently and wisely. You also reduce the burden on the environment,
        minimizing governmental e-waste and supporting national sustainability
        initiatives.
      </p>
      <p class="didYou">
        <span class="didBold">
          Extend Digital Transformation to Device End-of-Life
        </span>
        <br />
        Today, public sector organizations like yours face intense scrutiny in
        everything from spending to protecting sensitive data. By updating your
        policies and adopting Blancco’s software-based data sanitization
        solutions, you can reduce budget constraints and erase any end-of-life
        data concerns from your organization. All while ensuring your original
        SSDs may be safely reused, returned to a lessor, or otherwise prevented
        from heading to landfill.
      </p>
      <p class="didYou">
        Permanently erase end-of-life data and make your SSDs reusable—again and
        again—with Blancco.
      </p>
      <p class="didYou">
        Request a free trial for your organization today.
      </p>
      <b-row>
        <b-col>
          <b-button
            href="https://www.blancco.com/demo/free-trial-request-enterprise/?utm_source=benchmarking-tool-report&utm_medium=pdf&utm_content=enterprise-free-trial-cta&utm_campaign=publicsector"
            class="cta"
            >Get your free trial</b-button
          >
        </b-col>
        <b-col>
          <div>
            <b-img class="cert" :src="require('@/assets/img/cert.png')" />
          </div>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
export default {
  name: "didYou",
  props: ["question", "value"],
  computed: {},
};
</script>

<style scoped>
.didYou {
  text-align: left;
  font: normal normal normal 20px/32px "aktiv-grotesk";
  letter-spacing: 0px;
  color: #5d6770;
}
.didBold {
  font: normal normal bold 20px/32px "aktiv-grotesk";
  color: #253a6b;
}
img {
  width: 100%;
  margin: 32px 0;
}
.sStat {
  width: 80%;
}
.cta {
  margin-top: 59px;
  height: 44px;
  background: #3b5eab 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: 2px solid #ffffff;
  font: normal normal 500 18px/24px "aktiv-grotesk";
}
.title {
  background: transparent linear-gradient(92deg, #253a6b 0%, #3b5eab 100%) 0% 0%
    no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font: normal normal 500 28px/39px "aktiv-grotesk";
  padding-bottom: 16px;
}
.gOne {
  width: 455px;
}
.extra {
  padding-top: 48px;
}
.map {
  width: 44px;
  height: 24px;
  margin: 10px;
}
th {
  text-align: center;
  font: normal normal normal 14px/20px "aktiv-grotesk";
  color: #253a6b;
}
td {
  border: 1px solid #3b5eab;
  padding: 10px;
  text-align: center;
  font: normal normal 400 14px/20px "aktiv-grotesk";
  letter-spacing: 0px;
  color: #5d6770;
}
td:first-child {
  color: #253a6b;
  text-align: left;
}
th {
  border: 1px solid #3b5eab;
}
th:first-child {
  border: none;
}
.cert {
  margin-top: 59px;
  width: 245px;
}
</style>
