import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import test from "../views/pdfPage.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/FR",
    name: "Home FR",
    component: Home,
    props:true
  },
  {
    path: "/DU",
    name: "Home DU",
    component: Home,
    props:true
  },
  {
    path: "/JP",
    name: "Home JP",
    component: Home,
    props:true
  },
  {
    path: "/pdf",
    name: "test",
    component: test,
    props:true
  },
];

const router = new VueRouter({
  routes,
  mode:'history',
  // below will force scroll to the toop of the page when using the router
  
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  
});

export default router;
